import { TranslateService } from "@ngx-translate/core";
import { NgModule } from "@angular/core";
import { EventsService } from "../services/helpers/events.service";
import { CompanyMenu, Menu } from "./menus/company";
import { LandLordMenu } from "./menus/landlord";
import { PayerMenu } from "./menus/payer";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { NbMenuItem } from "@nebular/theme";
const dashboard = "Dashboard";
const units = "Units";
const contracts = "Contracts";
const invoices = "Collections";
const payers = "Payers";
const transfers = "Transfers";
const reports = "Reports";
const settings = "Settings";
const projects = "Projects";
const phases = "Phases";
const project_status = "Status";
const buildings = "Buildings";
const banks = "Banks";
const users = "Users";
const maintenance = "Maintenance";
const singleInvoice = "Single Invoice";
const groupInvoice = "Group Invoice";
const searchGroupInvoice = "Search Group Invoice";
const recurringInvoices = "Contract Invoices";
const receipts = "Receipts";
const projectMangment = "Project Management";
const announcement = "Announcement";
const approveRequest = "Approval Requests";
const mobileApp = "Mobile App"
const customer = "Customer";
// const owner = "Owner";

@NgModule({})
export class Userrole {
  constructor(
    private translateService: TranslateService,
    private eventService: EventsService
  ) {
    this.eventService.changeLangObser.subscribe((res) => {
      this.translateService.get(dashboard).subscribe((res) => {
        LandLordMenu[0].title = res;
        PayerMenu[0].title = res;
        Menu[0].title = res;
      });
      this.translateService.get(units).subscribe((res) => {
        LandLordMenu[1].title = res;
        Menu[1].title = res;
      });
      this.translateService.get(contracts).subscribe((res) => {
        LandLordMenu[2].title = res;
        PayerMenu[2].title = res;
        Menu[2].title = res;
      });
      this.translateService.get(invoices).subscribe((res) => {
        LandLordMenu[3].title = res;
        Menu[3].title = res;
      });
      this.translateService.get(recurringInvoices).subscribe((res) => {
        // Menu[3].children[0].title = res;
        LandLordMenu[3].children[0].title = res;
      });
      this.translateService.get(singleInvoice).subscribe((res) => {
        // Menu[3].children[1].title = res;
        LandLordMenu[3].children[1].title = res;
      });
      this.translateService.get(groupInvoice).subscribe((res) => {
        // Menu[3].children[2].title = res;
        LandLordMenu[3].children[2].title = res;
      });
      this.translateService.get(payers).subscribe((res) => {
        LandLordMenu[4].title = res;
      });
      this.translateService.get(customer).subscribe((res) => {
        Menu[4].title = res;
      });
      this.translateService.get(banks).subscribe((res) => {
        LandLordMenu[5].title = res;
        // Menu[5].title = res;
      });
      this.translateService.get(transfers).subscribe((res) => {
        LandLordMenu[6].title = res;
        Menu[5].title = res;
      });
      this.translateService.get(reports).subscribe((res) => {
        LandLordMenu[7].title = res;
        Menu[6].title = res;
      });
      this.translateService.get(receipts).subscribe((res) => {
        LandLordMenu[8].title = res;
        PayerMenu[3].title = res;
        // Menu[3].children[3].title = res;
      });
      this.translateService.get(mobileApp).subscribe((res) => {
        Menu[8].title = res;
      })
      this.translateService.get(settings).subscribe((res) => {
        LandLordMenu[10].title = res;
        // Menu[13].title = res;
      });
      this.translateService.get(maintenance).subscribe((res) => {
        PayerMenu[1].title = res;
        Menu[7].title = res;
        LandLordMenu[9].title = res;
      });
      // this.translateService.get(approveRequest).subscribe((res) => {
      //   Menu[8].children[0].title = res;
      // });
      // this.translateService.get(announcement).subscribe((res) => {
      //   Menu[8].children[1].title= res;
      // });
      this.translateService.get("English").subscribe((res) => {
        LandLordMenu[10].children[0].title = res;
        PayerMenu[4].title = res;
        Menu[9].title = res;
        if (localStorage.getItem("rental_lang") == "en" && res == "English") {
          this.translateService.get("عربي").subscribe((res) => {
            LandLordMenu[10].children[0].title = res;
            PayerMenu[4].title = res;
            Menu[9].title = res;
          });
        }
      });
    });
  }
}
