import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  private searchParamsSource = new BehaviorSubject("");
  private idsSource = new BehaviorSubject("");
  private pagesSource = new BehaviorSubject("");
  public currentPageSubject = new BehaviorSubject("");
  searchParams: Subject<any> = new Subject();
  ids: Subject<any> = new Subject();
  currentParams = this.searchParamsSource.asObservable();
  currentids = this.idsSource.asObservable();
  currentpage = this.pagesSource.asObservable();
  currentRolePages = this.currentPageSubject.asObservable();
  currentPage$: Observable<any>;

  // this is used in contract config for edit/save and set all values
  public currentEditStateSubject = new BehaviorSubject(false);
  currentEditState = this.currentEditStateSubject.asObservable();
  public currentEditValueSubject = new BehaviorSubject([]);
  currentEditValue = this.currentEditValueSubject.asObservable();

  constructor() {}

  storeSearchParams(params: any) {
    this.searchParamsSource.next(params);
  }
  storeIDs(amount: any) {
    this.idsSource.next(amount);
  }
  storePages(page) {
    this.pagesSource.next(page);
  }
  storeRolePages(pages) {
    this.currentPageSubject.next(pages);
  }
  get currentPageValue(): any {
    return this.currentPageSubject.value;
  }

  set currentPageValue(page: any) {
    this.currentPageSubject.next(page);
  }
  checkForAccess(routerLink) {
    if (typeof this.currentPageValue == "string") {
      if (JSON.parse(this.currentPageValue).some((r) => r.includes(routerLink)))
        return true;
    } else {
      if (this.currentPageValue.some((r) => r.includes(routerLink)))
        return true;
    }
  }
  getEditSate() {
    return this.currentEditState;
  }
  setEditSate(value) {
    this.currentEditStateSubject.next(value);
  }
  getEditValue() {
    return this.currentEditValue;
  }
  setEditValue(value) {
    const currentValue = this.currentEditValueSubject.getValue();
    if (
      !currentValue.some((oldvalue) => oldvalue.columnName === value.columnName)
    ) {
      const updatedValue = [...currentValue, value];
      this.currentEditValueSubject.next(updatedValue);
    }
  }
  clearEditValue(){
    this.currentEditValueSubject.next([])
  }
}
